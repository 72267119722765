<template>
  <base-layout>  
  <div class="home">
    <home-component></home-component>
  </div>
  </base-layout>
</template>

<script>
import HomeComponent from '../components/home/HomeComponent.vue';
export default {
    name:'home',
    components:{ HomeComponent},
    data(){
      return{}
    }

}
</script>
<style scoped>
.home{
  padding: auto;
  align-items: center;
  margin-top: 10%;
}
</style>